import PropTypes from 'prop-types';
import React from 'react';
import "./PageHeader.css";

const PageHeader = (props) => {
    return (
        <h5 className="heading heading-1">{props.children}</h5>
    );
}

PageHeader.propTypes = {
    children: PropTypes.node
}

export default PageHeader;