import axios from "axios";

export async function sendUserRequest({ email, password }) {
    return axios.post("/sendUserRequest",
        JSON.stringify({
            email, password
        }),
        {
            headers: { "Content-Type": "application/json" }
        },
    )
    .then((response) => {
        // If request is not successful, display error message
        if (response.status !== 200) {
            throw new Error("Error: Could not fetch the data!");
        }
        return response.data;
    })
    .catch((err) => {
        console.log('the error: \n')
        if(err.response){
            return err.response;
        } else if (err.request) {
            return err.request;
        } else {
            return err.message;
        }
    });
}
