import { i18nConfiguration, i18nDefaultConfiguration } from "src/configuration";
import * as t from "../types";

export interface MainState {
    stateToken: string
    factorId: string
    contactValue: string
    otpPassCode: any
    authRequest: any
    userId: string
    onboardStep: string
    i18nConfiguration: i18nConfiguration
}

const initialState: MainState = {
    stateToken : '',
    factorId: '',
    contactValue: '',
    otpPassCode: [],
    authRequest:'',
    userId:'',
    onboardStep:'',
    i18nConfiguration: i18nDefaultConfiguration
};

const main = (state = initialState, action ) => {
    switch(action.type){
        case t.SET_STATETOKEN:
            return{
                ...state,
                stateToken: action.payload.stateToken
            };
        case t.SET_FACTORID:
            return{
                ...state,
                factorId: action.payload.factorId
            };
        case t.SET_CONTACT_VALUE:
            return{
                ...state,
                contactValue: action.payload.contactValue
            };
        case t.SET_OTP_CODE:
            return{
                ...state,
                otpPassCode: action.payload.otp
            };
        case t.SET_AUTH_REQ:
            return{
                ...state,
                authRequest: action.payload.authnRequest
            };
        case t.SET_USER_ID:
            return{
                ...state,
                userId: action.payload.userId
            }
        case t.SET_ONBOARD_STEP:
            return{
                ...state,
                onboardStep: action.payload.onboardStep
            }
        case t.SET_I18N_CONFIGURATION:
            return{
                ...state,
                i18nConfiguration: action.payload.i18nConfiguration
            }
        default:
            return {...state};
    }
}

export default main;