import './Login.css'
import './tailwindy.css'

import { ApiLoginBody, GetAuthLoginResult, NavigateAction, RedirectAction } from 'src/services/gdp-iam-backend';
import { Col, Container, Row } from 'react-bootstrap';
import { FormDiv, FormWrapper, Html, Message, PageHeader } from 'src/Components';
import { Link, useSearchParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { GdpIamBackend } from 'src/services';
import LoginHeaderPicture from './LoginHeaderPicture';
import { WLoader } from 'src/Components/wrappers';
import { ZrButton } from '@zurich/web-components/react/button';
import { ZrPasswordInput } from '@zurich/web-components/react/password-input';
import { ZrTextInput } from '@zurich/web-components/react/text-input';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

function LoginAuth() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    const [message, setMessage] = useState('');
    const [isempty, setIsempty] = useState<{
        email: string | null,
        password: string | null
    }>({
        email: 'empty',
        password: 'empty'
    });
    const [iserror, setIsError] = useState({
        email: '',
        password: ''
    });

    const [searchParams, _] = useSearchParams();
    const [applicationName, setApplicationName] = useState('')
    const [pageError, setPageError] = useState('')

    const { t } = useTranslation('oauth');
    const navigate = useNavigate();

    useEffect(() => {
        async function init() {
            const clientId = searchParams.get('client_id')
            if (clientId) {
                const result: GetAuthLoginResult | null = await new GdpIamBackend().getAuthLogin(clientId)
                if (result) {
                    setApplicationName(result.applicationName || '')
                }
                else {
                    setPageError(`Unknown client '${clientId}.'`)
                }
            }
            else {
                setPageError('The \'client_id\' is not specified.')
            }
        }
        init()
    }, [])

    const handleClick = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        try {
            const data: ApiLoginBody = {
                username: email,
                password: password,
                encodedReturnUrl: searchParams.get('encodedReturnUrl') || ''
            }
            const response = await new GdpIamBackend().Login(data)
            if (response) {
                setErr('');
                const rootElement = response.rootElement
                if (rootElement) {
                    if (rootElement.type === 'NavigateAction') {
                        navigate((rootElement as NavigateAction).path!)
                    }
                    else
                    if (rootElement.type === 'RedirectAction') {
                        window.location.href = (rootElement as RedirectAction).redirectUrl!
                    }
                }
            }
            else {
                setErr('Something went wrong');
            }
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (name) => (value) => {
        setIsError(current => { return { ...current } });

        switch (name) {
            case "Login":
                setEmail(value);
                isempty.email = value.length === 0 ? "empty" : null;
                console.log(iserror.email)
                break;
            case "Password":
                setPassword(value);
                isempty.password = value.length === 0 ? "empty" : null;
                iserror.password =
                    value.length < 6 ? "At least 6 characters required" : "";
                break;
            default:
                break;
        }
        setIsError({
            ...iserror
        })
    };

    // go to forgot password section
    const forgotPassword = (e) => {
        navigate('/reset');
    }

    const loginButtonDisabled = Boolean(iserror.email) || Boolean(iserror.password) || Boolean(isempty.email) || Boolean(isempty.password) || isLoading;
    const loginText: string = t('login.Login')
    const passwordText: string = t('login.Password')
    const signInText: string = t('description.SignIn')
    const forgotPasswordText: string = t('description.forgotPassword')

    if (pageError) {
        return <div>Error: {pageError}</div>
    }

    return (
        <Container fluid className="d-flex flex-column container-no-padding">
            <LoginHeaderPicture />
            <Row className="login-header-row">
                <Col>
                    <PageHeader>
                        {
                            applicationName && 
                            <Html html={t("login.header", { applicationName: applicationName }) as string} />
                        }
                    </PageHeader>
                </Col>
            </Row>
            <Row className='justify-content-md-center flex-grow-1 login-row'>
                <Col>

                    <FormWrapper onSubmit={handleClick} className='form-group-1'>
                        <div className="forms--shape">
                            <FormDiv
                                classNameDiv="textfield user-input-wrp"
                                forhtml="Login"
                            >
                                <ZrTextInput
                                    id="field-input"
                                    config="shaped"
                                    name="Login"
                                    label={loginText}
                                    model={email}
                                    onChange={handleInputChange('Login')}
                                />
                            </FormDiv>
                        </div>

                        <div className="forms--shape">
                            <FormDiv
                                classNameDiv="textfield user-input-wrp"
                                forhtml="Password"
                            >
                                <ZrPasswordInput
                                    id="field-input-2"
                                    config="shaped"
                                    name="Password"
                                    label={passwordText}
                                    model={password}
                                    onChange={handleInputChange('Password')}
                                    invalid={Boolean(iserror.password)}
                                    help-text={iserror.password ? "This input is invalid" : ""}
                                />
                            </FormDiv>
                        </div>
                        <Message visible={err.length > 0}>
                            <p className='login-error'>{t('description.LoginErrorPart1')} <Link to='/reset'>{t('description.LoginErrorPart2')}</Link></p>
                        </Message>
                    </FormWrapper>
                    <WLoader loading={isLoading} />
                </Col>
            </Row>
            <Row className="mt-auto login-row">
                <Col className="d-flex flex-column align-items-center">
                    <ZrButton onClick={handleClick} wide={true} disabled={loginButtonDisabled}>{signInText}</ZrButton>
                    <div className="forgot-password-div">
                        <ZrButton onClick={forgotPassword} config="link" className="mt-2">{forgotPasswordText}</ZrButton>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default LoginAuth
