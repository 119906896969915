import axios from "axios";

export async function setSamlLogin(authnRequest, serviceProviderId) {
    return axios.post('/signin',
        JSON.stringify({
            authnRequest
        }),
        {
            headers: { 
                "Content-Type": "application/json",
                "serviceproviderid": serviceProviderId
            }
        },
    )
    .then((response) => {
        // If request is not successful, display error message
        if (response.status !== 200) {
            throw new Error("Error: Could not fetch the data!");
        }
        return response.data;
    })
    .catch((err) => {
        console.log('the error: \n')
        console.log(err)
        if(err.response){
            return err.response;
        } else if (err.request) {
            return err.request;
        } else {
            return err.message;
        }
    });
}