import './tailwindy.css'
import './LoginHeaderPicture.css'

import { Link, useNavigate } from 'react-router-dom'

import React from 'react'
import { ZrIcon } from '@zurich/web-components/react/icon'
import { ZrLogo } from '@zurich/web-components/react/logo'

const logo = require('../../Assets/LoginHeader.webp')

type LoginHeaderProps = {
    back?: boolean
}

function LoginHeaderPicture({
    back
}: LoginHeaderProps) {
    const navigate = useNavigate()

    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className="position-relative">
            <img src={logo} className="login-header-picture" alt="logo" />
            <div className="position-absolute w-100 d-flex align-items-center justify-content-between zurich-login-logo-container">
                <div onClick={goBack} className="back-link">
                    {
                        back &&
                        <ZrIcon
                            icon="arrow-long-left:line"
                            custom-str="color:#ffffff;size:28px"
                            class="zurich-login-icon"
                        />
                    }
                </div>
                <ZrLogo
                    config="inline"
                    custom-str="fill:#ffffff;size:8rem"
                    className="zurich-login-logo"
                />
                <Link to="/help" className="zurich-login-icon-link">
                    <ZrIcon
                        icon="help:line"
                        custom-str="color:#ffffff;size:28px"
                        class="zurich-login-icon"
                    />
                </Link>
            </div>
        </div>
    )
}

export default LoginHeaderPicture
