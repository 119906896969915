import React, { useEffect, useRef } from "react";
import { AcsResponse } from "src/models";

type SamlFormProps = {
    acsResponse: AcsResponse
}

function SamlForm({
    acsResponse
}: SamlFormProps) {
    const formRef = useRef<HTMLFormElement>(null)

    useEffect(() => {
        if (formRef.current) {
            formRef.current.submit()
        }
    }, [acsResponse])
    
    return (
        <form ref={formRef} method="POST" action={acsResponse.ACSUrl}>
            <input type="hidden" name="SAMLResponse" value={acsResponse.SAMLResponse} />
            <input type="hidden" name="RelayState" value={acsResponse.RelayState} />
        </form>
    )
}

export default SamlForm
