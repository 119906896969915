import React from 'react'
import { useTranslation } from 'react-i18next'
import { connect } from 'react-redux';

import './Footer.css'

type FooterProps = {
    marginTop?: boolean;
    i18nNamespace: string;
}

function Footer(props: FooterProps) {
    const { i18nNamespace } = props;
    const { t } = useTranslation(i18nNamespace);

    if (props.marginTop) {
        return (
            <footer className="footer-margin-top">
                <p dangerouslySetInnerHTML={{
                    __html: t('footer.message', {
                        year: new Date().getFullYear().toString()
                    }) || ''
                }} />
            </footer>
        )
    }

    return (
        <footer>
             <p dangerouslySetInnerHTML={{
                    __html: t('footer.message', {
                        year: new Date().getFullYear().toString()
                    }) || ''
                }} />
        </footer>
    )
}

const mapStateToProps = state => ({
    i18nNamespace: state.main.i18nConfiguration.namespace
})

export default connect(mapStateToProps)(Footer)
