import './Verification.css';

import { ApiVerifyBody, RedirectAction } from 'src/services/gdp-iam-backend';
import { Col, Container, Row } from 'react-bootstrap';
import React, { useState } from 'react';

import { GdpIamBackend } from 'src/services';
import { IconHeaderWithNavigation } from 'src/Components';
import { WLoader } from 'src/Components/wrappers';
import { ZrButton } from '@zurich/web-components/react/button';
import { ZrPictogram } from '@zurich/web-components/react/pictogram';
import { ZrTextInput } from '@zurich/web-components/react/text-input';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type VerificationAuthProps = {
  verificationType: 'email' | 'sms'
}

function VerificationAuth({
  verificationType
}: VerificationAuthProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [iserror, setIsError] = useState<string | null>('At least 6 characters required');
  const [message, setMessage] = useState('');
  const [searchParams, _] = useSearchParams();
  const contactValue = searchParams.get('contactValue')

  const emailVerification = verificationType === 'email';

  const header = emailVerification
    ? t('description.VerificationPromptHeaderEmail')
    : t('description.VerificationPromptHeaderPhone');

  const headerMfaType = emailVerification
    ? t('description.VerificationPromptHeaderEmailType')
    : t('description.VerificationPromptHeaderPhoneType');

  const combinedHeader = `${header} ${headerMfaType}`;

  const handleInputChange = (value) => {
    if (value.length >= 6) {
      setConfirmationCode(value);
      setIsError(null);
    }
    else {
      setIsError('At least 6 characters required');
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    try {
      const data: ApiVerifyBody = {
        stateToken: searchParams.get('stateToken') || '',
        factorId: searchParams.get('factorId') || '',
        encodedReturnUrl: searchParams.get('encodedReturnUrl') || '',
        passCode: confirmationCode
      }
      const responseData = await new GdpIamBackend().Verify(data)
      if (responseData) {
        setMessage('');
        const rootElement = responseData.rootElement
        if (rootElement && rootElement.type === 'RedirectAction') {
          window.location.href = (rootElement as RedirectAction).redirectUrl!
        }
      } else {
        setErr('500 internal error');
        setMessage(t('Verification.WrongCode') as string);
      }
    } catch (err: any) {
      console.error(err)
      setErr(err.message);
      setMessage(t('description.ActivationErrorText') as string)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className='verification-box'>
      <Container>
        <IconHeaderWithNavigation />
        <Row className="pictogram-container">
          {emailVerification
            ? <ZrPictogram pictogram="mail:dark" />
            : <ZrPictogram pictogram="mobile-call:dark" />
          }
        </Row>
        <Row>
          <Col>
            <h3 className='verify-header'>{combinedHeader}</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className='verification-prompt-sub'>{t('description.VerificationResponse1')}<b>{contactValue}</b> {t('description.VerificationResponse2')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="forms--shape">
              <ZrTextInput
                  id="field-input" 
                  config="shaped"
                  name="ConfirmationCode" 
                  label={t('Verification.confirmationCode') as string}
                  onChange={handleInputChange}
              />
            </div>
            <div className="message">{err.length > 0 ? <p className="verification-error">{message}</p> : null}</div>
            <div className='forms--shape'>
              <ZrButton 
                onClick={handleClick} 
                wide={true} 
                disabled={iserror !== null || isLoading}>
                {header}
              </ZrButton>
            </div>
            <WLoader loading={isLoading} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default VerificationAuth;
