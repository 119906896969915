import { ApiLoginBody, ApiVerifyBody, GetAuthLoginResult, LoginResponse, VerifyResponse } from './gdp-iam-backend'

import axios from 'axios';

export class GdpIamBackend {
  async getAuthLogin(clientId: string): Promise<GetAuthLoginResult | null> {
    return axios
      .get(`/auth/api/auth-login?clientId=${clientId}`, 
      {
        headers: { "Content-Type": "application/json" }
      })
      .then(response => {
        return response.data;
      })
      .catch((err) => {
        return null
      })
  }

  Login(data: ApiLoginBody): Promise<LoginResponse> {
    return axios
      .post('/auth/api/login', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  }

  Verify(data: ApiVerifyBody): Promise<VerifyResponse> {
    return axios
      .post('/auth/api/verify', data)
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.log(error)
      })
  }
}
