import axios from 'axios';

axios.defaults.withCredentials = true;

export async function getAuthnRequest(){
  return axios
    .get('/saml/authn-request', 
    {
      headers: { "Content-Type": "application/json" }
    })
    .then(response => {
      if (response.statusText === 'OK')
      {
        // If request is not successful, display error message
        return response.data;
      }
    })
    .catch((err) => {
      console.error(err);
      return null;
    })
}
