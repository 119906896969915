import axios from "axios";

const getuserDetailsPrimaryDataRoute = "/getuserDetailsPrimaryData/";
const getuserDetailsPrimaryDataByLoginRoute = "/getuserDetailsPrimaryDataByLogin";
const getUserMfaPolicyRoute = "/getuserMfaPolicyByLogin";
const updateUserDetailsRoute = "/updateUserDetailsData";
const activateUserRoute = "/activateUser";
const enrollUserRoute = "/enrollUserFactor";

/**
 * Get user details.
 * @param {string} userID 
 * @returns user data
 */
export async function getUserDetails( userID ) {

    return axios.get(getuserDetailsPrimaryDataRoute + userID,
        {
            headers: { "Content-Type": "application/json" }
        },
    )
    .then((response) => {
        // If request is not successful, display error message
        if (response.status !== 200) {
            // console.log(response)
            throw new Error("Error: Could not fetch the data!");
        }
        //parsing userData
        let userData = {};

        if(response){
            // console.log(response);
            userData.userEmail = response.data.profile.credentials.login;
            userData.firstName = response.data.profile.person.firstName;
            userData.lastName = response.data.profile.person.lastName;

            const found = response.data.profile.person.contact.find(function(item) {
                return item.contactType === "Home" || item.contactType === "Phone" || item.contactType === "Mobile";
            })
            userData.PhoneNumber = found ? found.contactValue : "";

            userData.userDetails = response.data;
            return userData;
        }
        return userData;
    })
    .catch((err) => {
        console.log('the error: \n')
        // console.log(err)
        if(err.response){
            // console.log(err.response)
            return err.response;
        } else if (err.request) {
            // console.log(err.request)
            return err.request;
        } else {
            // console.log(err.message)
            return err.message;
        }
    });
}

/**
 * Get user details.
 * @param {string} userLogin 
 * @returns user data
 */
export async function getUserDetailsByLogin(userLogin) {
    return axios.post(getuserDetailsPrimaryDataByLoginRoute,
        JSON.stringify({
            userLogin
        }),
        {
            headers: { "Content-Type": "application/json" }
        },
    )
        .then((response) => {
            // If request is not successful, display error message
            if (response.status !== 200) {
                // console.log(response)
                throw new Error("Error: Could not fetch the data!");
            }
            //parsing userData
            let userData = {};

            if (response) {
                // console.log(response);
                userData.userEmail = response.data.profile.credentials.login;
                userData.firstName = response.data.profile.person.firstName;
                userData.lastName = response.data.profile.person.lastName;

                const found = response.data.profile.person.contact.find(function (item) {
                    return item.contactType === "Home" || item.contactType === "Phone" || item.contactType === "Mobile";
                })
                userData.PhoneNumber = found ? found.contactValue : "";

                userData.userDetails = response.data;
                return userData;
            }
            return userData;
        })
        .catch((err) => {
            console.log('the error: \n')
            // console.log(err)
            if (err.response) {
                // console.log(err.response)
                return err.response;
            } else if (err.request) {
                // console.log(err.request)
                return err.request;
            } else {
                // console.log(err.message)
                return err.message;
            }
        });
}

/**
 * Get user mfa policy by login.
 * @param {string} userLogin 
 * @returns user data
 */
export async function getMfaPolicy(userLogin) {
    return axios.post(getUserMfaPolicyRoute,
        JSON.stringify({
            userLogin
        }),
        {
            headers: { "Content-Type": "application/json" }
        },
    )
    .then((response) => {
      if (response.status !== 200) {
        throw new Error("Error: Could not fetch the data!");
      }
      if (response) {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      } else if (err.request) {
         return err.request;
      } else {
        return err.message;
      }
    });
}

/**
 * Update user details.
 * @param {string} userID user id
 * @param {string} firstName first name
 * @param {string} lastName last name
 * @param {string} phoneNumber phone number
 * @param {string[]} oldContacts existing contacts
 * @returns updated user data
 */
export async function updateUserDetails(userID, firstName, lastName, phoneNumber, oldContacts) {
    return axios.put(updateUserDetailsRoute,
        JSON.stringify({
            userID,
            firstName,
            lastName,
            phoneNumber,
            oldContacts
        }),
        {
            headers: { "Content-Type": "application/json" }
        },
    )
    .then((response) => {
        // If request is not successful, display error message
        if (response.status !== 200) {
            // console.log(response)
            throw new Error("Error: Could not fetch the data!");
        }
        return response.data;
    })
    .catch((err) => {
        console.log('the error: \n')
        // console.log(err)
        if(err.response){
            // console.log(err.response)
            return err.response;
        } else if (err.request) {
            // console.log(err.request)
            return err.request;
        } else {
            // console.log(err.message)
            return err.message;
        }
    });
}

export async function activateUser(stateToken, factorId, passCode){
    console.log("Activate User");

    console.log("StateToken - " + stateToken);
    console.log("FactorId - " + factorId);
    console.log("PassCode - " + passCode);
    
    return axios.post(activateUserRoute,
        JSON.stringify({
            stateToken,
            factorId,
            passCode
        }),
        {
            headers: { "Content-Type": "application/json" }
        }
    ).then((response) => {
        if(response.status !== 200){
            throw new Error(`Error: Received http status ${response.status} from server`);
        }

        return response.data;
    });
}

export async function enrollUserFactor(stateToken, factorType, provider, phoneNumber, userEmail) {
    return axios.post(enrollUserRoute,
        JSON.stringify({
            stateToken,
            factorType,
            provider,
            mobilePhoneNumber: phoneNumber,
            userEmail
        }),
        {
            headers: { "Content-Type": "application/json" }
        }
    ).then((response) => {
        if(response.status !== 200){
            throw new Error(`Error: Received http status ${response.status} from server`);
        }

        return response.data;
    });
}
