import { Breakpoint, BreakpointProvider } from 'react-socks';

import AppDesktop from './AppDesktop';
import AppTablet from './AppTablet';
import AppMobile from './AppMobile';
import React from 'react';

function App() {
  return (
    <BreakpointProvider>

      <Breakpoint customQuery="(max-width: 576px)">
        <AppMobile />
      </Breakpoint>

      <Breakpoint customQuery="(min-width: 577px) and (max-width: 1199px)">
        <AppTablet />
      </Breakpoint>

      <Breakpoint customQuery="(min-width: 1200px)">
        <AppDesktop />
      </Breakpoint>
    
    </BreakpointProvider>
  );
}

export default App;
