import React from 'react'

type HtmlProps = {
    html: string
}

function Html({ html }: HtmlProps) {
    return  (
        <div dangerouslySetInnerHTML={{__html: html }}/>
    )
}

export default Html
