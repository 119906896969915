import axios from "axios";

export async function setVerification({ factorID, stateToken, passCode }) {

    return axios.post("/sendUserVerification",
        JSON.stringify({
            factorID, stateToken, passCode
        }),
        {
            headers: { "Content-Type": "application/json" }
        },
    )
    .then((response) => {
        // If request is not successful, display error message
        if (response.status !== 200) {
            throw new Error("Error: Could not fetch the data!");
        }
        return response.data;
    })
    .catch((err) => {
        console.log('the error: \n')
        console.log(err)
        throw new Error("Error: Could not fetch the data!");
    });
}