import * as de from './translations/de'
import * as en from './translations/en'
import * as es from './translations/es'

import { i18nLanguages, i18nNamespaces } from 'src/configuration'

import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  //detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: true,
    // having a common namespaces in the full app
    ns: [
      i18nNamespaces.translation,
      i18nNamespaces.ireland,
      i18nNamespaces.gdpDashboardAmmi,
      i18nNamespaces.liveWell,
      i18nNamespaces.oauth
    ],
    defaultNS: i18nNamespaces.translation,
    fallbackNS: i18nNamespaces.translation,

    supportedLngs: [
      i18nLanguages.en,
      i18nLanguages.de,
      i18nLanguages.es
    ],
    fallbackLng: i18nLanguages.en,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ","
    },
    resources: {
      en: {
        translation: en.translation,
        ireland: en.ireland,
        gdpDashboardAmmi: en.gdpDashboardAmmi,
        liveWell: en.liveWell,
        oauth: en.oauth
      },
      de: {
        translation: de.translation,
        ireland: de.ireland,
        gdpDashboardAmmi: de.gdpDashboardAmmi,
        liveWell: de.liveWell,
        oauth: de.oauth
      },
      es: {
        translation: es.translation,
        ireland: es.ireland,
        gdpDashboardAmmi: es.gdpDashboardAmmi,
        liveWell: es.liveWell,
        oauth: es.oauth
      }
    },
    react: {
      useSuspense: true
    }
  });

export default i18n;