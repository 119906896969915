import { i18nDefaultConfiguration, i18nNamespaces } from "src/configuration";

export function extractI18nConfigurationFromAuthRequest(authRequest) {
    const receivedAuthObject = authRequest;

    if (receivedAuthObject.i18nConfiguration === undefined) {
        return i18nDefaultConfiguration;
    }

    return receivedAuthObject.i18nConfiguration;
}

export function extractI18nConfigurationFromUserDetails(userDetails) {
    if (userDetails.profile.businessUnit === "IE_GI_Zurich_Ireland") {
        return {
            namespace: i18nNamespaces.ireland
        }
    }

    return i18nDefaultConfiguration;
}