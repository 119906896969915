import React from 'react'
import { ZrLoader } from "@zurich/web-components/react/loader";

type WLoaderProps = {
    loading: boolean
}

function WLoader({
    loading
}: WLoaderProps): React.JSX.Element | null {
    if (!loading) {
        return null
    }
    return <ZrLoader />
}

export default WLoader
