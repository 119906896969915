import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import alert from '../Assets/alert.svg';
import './AlertHeader.css'

function AlertHeader() {
    return (
        <Container>
            <Row >
                <Col>
                    <div className='App-header'>
                        <img src={alert} className="logo-zurich-primary" alt="alert" height={100}/>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default AlertHeader;