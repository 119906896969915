import axios from "axios";
import {Base64} from 'js-base64';
import { setSamlLogin } from 'src/Middleware/setSamlLogin';
import { buildUserConfigurationByLogin } from 'src/Middleware/user';

export async function setSaml(authRequest, responseData) {
  if (authRequest !== '' && authRequest !== null && authRequest !== undefined) {
    try{
      axios.defaults.withCredentials = true; //Setting the withCredentials to true allows the cookie for cross domain requests

      //decode the received auth request
      let receivedAuthObject = authRequest
  
      let serviceProviderId = receivedAuthObject.participant.serviceProviderId;
      let userLogin = responseData.user ? responseData.user.profile.credentials.login : responseData;
      //check for internal users
      let userLoginToBuildConfiguration;
      if(responseData.user)
      {
        userLoginToBuildConfiguration = userLogin;
      }else{
        userLoginToBuildConfiguration = responseData;
      }
      
      const userConfiguration = await buildUserConfigurationByLogin(userLoginToBuildConfiguration, serviceProviderId);
  
      receivedAuthObject.user.login = responseData.user ? responseData.user.profile.credentials.login : responseData;
      receivedAuthObject.type = userConfiguration.type;
      receivedAuthObject.role = userConfiguration.role;
      receivedAuthObject.group = userConfiguration.group;
  
      //encode and send the updated value
      let sendAuthRequest = Base64.encode(JSON.stringify(receivedAuthObject));
      return await setSamlLogin(sendAuthRequest, serviceProviderId);
    }catch(err){
      console.error(err);
      return null;
    }
    
  }
  return null;
}