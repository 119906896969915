import PropTypes from 'prop-types';
import React from 'react';
import './FormWrapper.css'

const FormWrapper = (props) => {
    return (
        <form
            noValidate
            onSubmit={props.onSubmit} 
            className={props.className}>
            {props.children}
        </form>
    )
}

FormWrapper.propTypes = {
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.node,
};
  
export default FormWrapper;