import './Verification.css';

import { Col, Container, Row } from 'react-bootstrap';
import { IconHeaderWithNavigation, SamlForm, SuccessHeader } from 'src/Components';
import React, { useState } from 'react';

import { AcsResponse } from 'src/models';
import { Base64 } from 'js-base64';
import { MainState } from 'src/Utils/redux/reducers/main';
import { WLoader } from 'src/Components/wrappers';
import { ZrButton } from '@zurich/web-components/react/button';
import { ZrPictogram } from '@zurich/web-components/react/pictogram';
import { ZrTextInput } from '@zurich/web-components/react/text-input';
import { activateUser } from 'src/Middleware/setUserOnboard';
import axios from 'axios';
import { buildUserConfiguration } from 'src/Middleware/user';
import { connect } from 'react-redux';
import { setSamlLogin } from 'src/Middleware/setSamlLogin';
import { setVerification } from 'src/Middleware/setVerification';
import { useTranslation } from 'react-i18next';

type VerificationProps = {
  stateinfo: MainState
  verificationType: 'email' | 'sms'
}

function Verification({
  stateinfo,
  verificationType
}: VerificationProps) {
  const { t } = useTranslation(stateinfo.i18nConfiguration.namespace);
  const [isLoading, setIsLoading] = useState(false);
  const [isActivationSuccess, setIsActivationSuccess] = useState(false);
  const [err, setErr] = useState('');
  const [confirmationCode, setConfirmationCode] = useState('');
  const [iserror, setIsError] = useState<string | null>('At least 6 characters required');
  const [message, setMessage] = useState('');

  const [acsResponse, setAcsResponse] = useState<AcsResponse | null>(null);

  axios.defaults.withCredentials = true; //Setting the withCredentials to true allows the cookie for cross domain requests

  const emailVerification = verificationType === 'email';

  let header = emailVerification
    ? t('description.VerificationPromptHeaderEmail')
    : t('description.VerificationPromptHeaderPhone');

  const headerMfaType = emailVerification
    ? t('description.VerificationPromptHeaderEmailType')
    : t('description.VerificationPromptHeaderPhoneType');

  const combinedHeader = `${header} ${headerMfaType}`;

  if (isActivationSuccess) {
    header = t('description.ActivationSuccessHeader');
  }

  function maskContact(contactValue) {
    if (emailVerification) {
      const [localPart, domainPart] = contactValue.split('@');
      if (localPart.length < 6) {
          return contactValue;
      }

      const maskedLocalPart = localPart.slice(0, 4) + '*'.repeat(localPart.length - 4);
      return maskedLocalPart + '@' + domainPart;
    }
    else {
        if (contactValue.length < 6) {
            return contactValue;
        }

        const numStars = contactValue.length - 6;
        return contactValue.slice(0, 4) + '*'.repeat(numStars) + contactValue.slice(-2);
    }
  }

  const handleInputChange = (value) => {
    if (value.length >= 6) {
      setConfirmationCode(value);
      setIsError(null);
    }
    else {
      setIsError('At least 6 characters required');
    }
  };

  const handleClick = async (e) => {
    setIsLoading(true);

    e.preventDefault();
    try {
      let responseData: any = undefined;

      if (stateinfo.onboardStep === 'ACTIVATE') {
        responseData = await activateUser(stateinfo.stateToken, stateinfo.factorId, confirmationCode);
      } else {
        responseData = await setVerification({ factorID: stateinfo.factorId, stateToken: stateinfo.stateToken, passCode: confirmationCode });
      }

      if (responseData) {
        setMessage('');
        if (stateinfo.authRequest && stateinfo.authRequest.participant) {
          const receivedAuthObject = stateinfo.authRequest;

          const serviceProviderId = receivedAuthObject.participant.serviceProviderId;

          const userConfiguration = await buildUserConfiguration(responseData.user.ID, serviceProviderId);

          receivedAuthObject.user.login = responseData.user.profile.credentials.login
          receivedAuthObject.type = userConfiguration.type;
          receivedAuthObject.role = userConfiguration.role;
          receivedAuthObject.group = userConfiguration.group;

          //encode and send the updated value
          const sendAuthRequest = Base64.encode(JSON.stringify(receivedAuthObject));

          const acsResponse = await setSamlLogin(sendAuthRequest, serviceProviderId);
          setAcsResponse(acsResponse);
        } else {
          setIsActivationSuccess(true);
        }
      } else {
        setErr('500 internal error');
        setMessage(t('Verification.WrongCode') as string);
      }
    } catch (err: any) {
      console.error(err)
      setErr(err.message);
      setMessage(t('description.ActivationErrorText') as string)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className='verification-box'>
      <Container>
        {isActivationSuccess
          ? <SuccessHeader />
          : <IconHeaderWithNavigation />
        }
        <Row className="pictogram-container">
          {emailVerification
            ? <ZrPictogram pictogram="mail:dark" />
            : <ZrPictogram pictogram="mobile-call:dark" />
          }
        </Row>
        <Row>
          <Col>
            <h3 className='verify-header'>{combinedHeader}</h3>
          </Col>
        </Row>
        {isActivationSuccess
          ? <Row>
            <Col>
              <p className='verification-prompt-sub'>{t('description.ActivationSuccessText')}</p>
            </Col>
          </Row>
          : <>
            <Row>
              <Col>
                <p className='verification-prompt-sub'>{t('description.VerificationResponse1')}<b>{maskContact(stateinfo.contactValue)}</b> {t('description.VerificationResponse2')}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="forms--shape">
                  <ZrTextInput
                      id="field-input" 
                      config="shaped"
                      name="ConfirmationCode" 
                      label={t('Verification.confirmationCode') as string}
                      onChange={handleInputChange}
                  />
                </div>
                <div className="message">{err.length > 0 ? <p className="verification-error">{message}</p> : null}</div>
                <div className='forms--shape'>
                  <ZrButton 
                    onClick={handleClick} 
                    wide={true} 
                    disabled={iserror !== null || isLoading || acsResponse !== null}>
                    {header}
                  </ZrButton>
                </div>
                <WLoader loading={isLoading || acsResponse !== null} />
              </Col>
            </Row>
          </>
        }
        {
          acsResponse !== null &&
          <SamlForm acsResponse={acsResponse} />
        }
      </Container>
    </div>
  )
}

const mapStateToProps = state => ({
  stateinfo: state.main
});

const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(Verification);
