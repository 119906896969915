import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import "./Onboard.css";
import IconHeader from "src/Components/IconHeader";
import PageHeader from "src/Components/PageHeader";
import SubmitButton from "src/Components/SubmitButton";
import Spinner from "src/Components/Spinner";

import { verifyResetPasswordToken } from "../../Middleware/verifyResetPasswordToken";
import {
  getUserDetails,
  updateUserDetails,
} from "src/Middleware/setUserOnboard";
import { sendUserId, sendI18nConfiguration } from "src/Utils/redux/actions/main";
import { extractI18nConfigurationFromUserDetails } from "src/Utils/extractI18nConfiguration";
import { defaultI18nLanguage } from 'src/configuration';

const regExpFirstName = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+){0,5})((\s([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+){0,5})){0,10})|(".+"))$/
);

const regExpLastName = RegExp(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+){0,5})|(".+"))$/
);
const regExpPhone = RegExp(
  /^[+]?\(?\d{3}\)?[-\s.]?\d{3}[-\s.]?\d{4,6}$/
);

const Onboard = (props) => {
  //getting the initial state and actions
  const { stateinfo, sendUserId, sendI18nConfiguration } = props;
  // console.log(props);
  const [searchParams, setSearchParams] = useSearchParams();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [userBody, setUserBody] = useState("");
  const [PhoneNumber, setPhoneNUmber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userID, setUserID] = useState("");
  const [err, setErr] = useState("");
  const [message, setMessage] = useState("");

  const trackUserID = useRef("");
  const [iserror, setIsError] = useState({
    FirstName: "",
    LastName: "",
    PhoneNumber: "",
  });

  // for translation
  const { t, i18n } = useTranslation(stateinfo.i18nConfiguration.namespace);
  // for navigation
  let navigate = useNavigate();

  //get token value
  let token = searchParams.get("token");

  useEffect(() => {
    setIsLoading(true);

    const getUserIdData = async () => {
      let validationResult = await verifyResetPasswordToken(token);
      if (validationResult.error || validationResult === false) {
        console.log("token not valid");
        if (validationResult.data && validationResult.data.status) {
          navigate("/resend-activation-link?status=" + validationResult.data.status);
        } else {
          navigate("/resend-activation-link");
        }
        return
      }

      setUserID(validationResult);
      //sending to redux
      let useridTosSend = {};
      useridTosSend.userId = validationResult;
      sendUserId(useridTosSend);

      trackUserID.current = validationResult;

      //calling get user by ID to get all details
      let userDetails = await getUserDetails(trackUserID.current);

      if (!userDetails || userDetails === {}) {
        console.log("valid user could not be found");
        // navigate("/");
      }

      const i18nConfiguration = extractI18nConfigurationFromUserDetails(userDetails.userDetails);
      sendI18nConfiguration({ i18nConfiguration: i18nConfiguration });

      //getting the preferred language of the user
      let preferredLanguage = userDetails.userDetails.profile.person.preferredLanguage || defaultI18nLanguage;
      //setting i18n language
      i18n.changeLanguage(preferredLanguage);

      //setting all the user details
      setEmail(userDetails.userEmail);
      setFirstName(userDetails.firstName);
      setLastName(userDetails.lastName);
      setPhoneNUmber(userDetails.PhoneNumber);
      setUserBody(userDetails.userDetails);
      setIsLoading(false);
    };

    if (!userID) {
      getUserIdData()
        .catch(err => {
          console.error(err);
          navigate("/") //Something happened. Fallback is to redirect to homepage.
        });
    }
  }, [token, userID]);

  // form value change
  const formValChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setIsError((current) => {
      return { ...current };
    });

    switch (name) {
      case "FirstName":
        setFirstName(e.target.value);
        iserror.FirstName = regExpFirstName.test(value)
          ? ""
          : "First Name is invalid";

        console.log(iserror.FirstName);
        break;
      case "LastName":
        setLastName(e.target.value);
        iserror.LastName = regExpLastName.test(value) ? "" : "Last Name is invalid";
        break;
      case "PhoneNumber":
        setPhoneNUmber(e.target.value);
        iserror.PhoneNumber = regExpPhone.test(value)
          ? ""
          : "Phone Number invalid";
        break;
      default:
        break;
    }
    setIsError({
      ...iserror,
    });
  };

  // handle click operation
  const handleClick = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const responseData = updateUserDetails(userID, FirstName, LastName, PhoneNumber, userBody.profile.person.contact);
      if (responseData) {
        navigate("/set-password");
      } else {
        setMessage("Response of unknown origin! ");
        setErr("Response of unkown origin");
      }
    } catch (err) {
      console.log(err);
      // console.error(err)
      setErr(err.message);
      setMessage("The server didnot return a response");
    } finally {
      setIsLoading(false);
    }
  };

  const saveDisabled = iserror.FirstName.length > 0 || iserror.LastName.length > 0 || iserror.PhoneNumber.length > 0 || !FirstName || !LastName;
  return (
    <Container>
      <IconHeader></IconHeader>
      <Row>
        <Col>
          <PageHeader>{t("onboardSetup.Header")}</PageHeader>
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="headline-h6">{Email ? Email : ""}</p>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md="fluid">
          {(!Email || isLoading) && <Spinner />}
          <form onSubmit={handleClick} noValidate className="form-group-1">
            <div className="forms--shape">
              <div className="textfield user-input-wrp">
                <input
                  id="field-input"
                  name="FirstName"
                  disabled={isLoading}
                  className={
                    iserror.FirstName.length > 0
                      ? "is-invalid textfield-control grey-input"
                      : "textfield-control grey-input"
                  }
                  value={FirstName}
                  type="text"
                  onChange={formValChange}
                  placeholder = " "
                />
                {iserror.FirstName.length > 0 && (
                  <span className="invalid-feedback">{iserror.FirstName}</span>
                )}
                <label className={iserror.FirstName.length > 0 ? "is-invalid float-label" : "float-label"} htmlFor="FirstName">
                  {t("onboardSetup.FirstName")}
                </label>
              </div>
            </div> 
          
            <div className="forms--shape">
              <div className="textfield user-input-wrp">
                <input
                  id="field-input-2"
                  name="LastName"
                  disabled={isLoading}
                  className={
                    iserror.LastName.length > 0
                      ? "is-invalid textfield-control grey-input"
                      : "textfield-control grey-input"
                  }
                  value={LastName}
                  type="text"
                  onChange={formValChange}
                  placeholder = " "
                />
                {iserror.LastName.length > 0 && (
                  <span className="invalid-feedback">{iserror.LastName}</span>
                )}
                <label className={iserror.LastName.length > 0 ? "is-invalid float-label" : "float-label"} htmlFor="LastName">
                  {t("onboardSetup.LastName")}
                </label>
              </div>
            </div>
            <div className="forms--shape">
              <div className="textfield user-input-wrp">
                <input
                  id="field-input-3"
                  name="PhoneNumber"
                  disabled={isLoading}
                  className={
                    iserror.PhoneNumber.length > 0
                      ? "is-invalid textfield-control grey-input"
                      : "textfield-control grey-input"
                  }
                  value={PhoneNumber}
                  type="text"
                  onChange={formValChange}
                  placeholder = " "
                />
                {iserror.PhoneNumber.length > 0 && (
                  <span className="invalid-feedback">
                    {iserror.PhoneNumber}
                  </span>
                )}
                <label className={iserror.PhoneNumber.length > 0 ? "is-invalid float-label" : "float-label"} htmlFor="PhoneNumber">
                {t("onboardSetup.PhoneNumber")}
                </label>
              </div>
            </div>
            <div className="message">
              {err.length > 0 ? (
                <p className="submit-error">
                  {t("description.LoginErrorPart1")}{" "}
                </p>
              ) : null}
            </div>
            <SubmitButton 
              onClick={handleClick} 
              disabled={saveDisabled}>
                {t("onboardSetup.SaveButton")}
            </SubmitButton>
          </form>
        </Col>
      </Row>
    </Container>
  );
};

Onboard.propTypes = {
  stateinfo: PropTypes.object,
  sendUserId: PropTypes.func,
  sendI18nConfiguration: PropTypes.func
}

const mapStateToProps = (state) => ({
  stateinfo: state.main,
});

const mapDispatchToProps = {
  sendUserId,
  sendI18nConfiguration
};

export default connect(mapStateToProps, mapDispatchToProps)(Onboard);
