import './Help.css';

import { Contacts } from '../../configuration';
import React from 'react';
import { useTranslation, } from "react-i18next";
import { connect } from 'react-redux';

type HelpProps = {
  i18nNamespace: string;
}

function Help(props: HelpProps) {
  const { i18nNamespace } = props;
  const { t } = useTranslation(i18nNamespace);

  return (
    <div className='help-body'>
      <div dangerouslySetInnerHTML={{__html: t('help.message', {
          phone: Contacts.ServiceDeskPhone,
          email: Contacts.ServiceDeskEmail
        }) || ''}}/>
    </div>
  )
}

const mapStateToProps = (state) => ({
  i18nNamespace: state.main.i18nConfiguration.namespace
});

export default connect(mapStateToProps)(Help);
