export async function getConfiguration(serviceProviderId)
{
    return await fetch("/configuration" + '?' + new URLSearchParams({
        serviceProviderId
    }), {
        method: 'GET',
        headers: { "Content-Type": "application/json" }
    })
    .then(async(response) => {
        if(response.ok)
        {
            return await response.json();
        }
    }).catch((err) => {
        console.error(err);
        return false;
    })
}