import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import success from '../Assets/success.svg';
import './SuccessHeader.css'

function SuccessHeader() {
    return (
        <Container>
            <Row >
                <Col>
                    <div className='App-header'>
                        <img src={success} className="logo-zurich-primary" alt="success" />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default SuccessHeader;