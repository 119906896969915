import PropTypes from 'prop-types'
import React from 'react'
import './RadioSelectItem.css'

const RadioSelectItem = (props) => {
    return (
        <label className="radio two-factor-choice">
            <input
                id={props.id}
                name={props.name}
                className={props.className}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                title={props.title}
            />
            <span className="radio-label" title={props.title}>{props.text}</span>
        </label>
    )
}

RadioSelectItem.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    text: PropTypes.string.isRequired,
};

export default RadioSelectItem;