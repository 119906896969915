import PropTypes from 'prop-types'
import React from 'react'
import './SubmitButton.css'

const SubmitButton = (props) => {
    return (
        <button
            type="submit"
            className="btn btn-primary btn-submit-general"
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    )
}

SubmitButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    children: PropTypes.node,
};

export default SubmitButton;