import React from 'react';
import logo from '../Assets/Zurich_Logo_Horz_Blue.svg';
import './IconHeader.css'

function IconHeader() {
    return (
        <img src={logo} className="icon-header-logo" alt="logo" />
    )
}

export default IconHeader;