import './AppTablet.css';

import AppRoutes from './AppRoutes'
import Navigation from './Components/Navigation';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './Components/Footer';

function AppMobile() {
  return (
    <Router>
      <div className="app-tablet-grid-container">
        <div className="app-tablet-grid-item-header">
            <Navigation showLabel={false} />
        </div>
        <div className="app-tablet-grid-item-left" />
        <main className="app-tablet-grid-item-main">
          <div className="main-content">
            <AppRoutes />
            <Footer marginTop />
          </div>
        </main>
      </div>
    </Router>
  )
}

export default AppMobile
