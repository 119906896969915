import './Login.css';
import './tailwindy.css'

import { Col, Container, Row } from 'react-bootstrap'
import { FormDiv, FormWrapper, Message, PageHeader, SamlForm } from 'src/Components'
import React, { useEffect, useState } from 'react'
import { extractI18nConfigurationFromAuthRequest, extractI18nConfigurationFromUserDetails } from 'src/Utils/extractI18nConfiguration'
import {
    sendAuthnRequest,
    sendContactValueData,
    sendFactorIdData,
    sendI18nConfiguration,
    sendStateTokenData
} from 'src/Utils/redux/actions/main'

import { AcsResponse } from 'src/models'
import { Link } from 'react-router-dom'
import LoginHeaderPicture from './LoginHeaderPicture'
import { MainState } from 'src/Utils/redux/reducers/main'
import { WLoader } from 'src/Components/wrappers'
import { ZrButton } from '@zurich/web-components/react/button'
import { ZrPasswordInput } from '@zurich/web-components/react/password-input'
import { ZrTextInput } from '@zurich/web-components/react/text-input'
import { connect } from 'react-redux'
import { getAuthnRequest } from '../../Middleware/getAuthnRequest'
import { getUserLogin } from '../../Middleware/getUserLogin'
import { sendUserRequest } from 'src/Middleware/sendUserRequest'
import { setSaml } from 'src/Middleware/setSaml'
import { useNavigate } from 'react-router'
import { useTranslation } from 'react-i18next'

type LoginProps = {
    stateinfo: MainState
    sendFactorIdData(value: any): void
    sendStateTokenData(value: any): void
    sendContactValueData(value: any): void
    sendAuthnRequest(value: any): void
    sendI18nConfiguration(value: any): void
}

function Login({
    stateinfo,
    sendFactorIdData,
    sendStateTokenData,
    sendContactValueData,
    sendAuthnRequest,
    sendI18nConfiguration
}: LoginProps) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [err, setErr] = useState('');
    const [isempty, setIsempty] = useState<{
        email: string | null,
        password: string | null
    }>({
        email: 'empty',
        password: 'empty'
    });
    const [iserror, setIsError] = useState({
        email: '',
        password: ''
    });

    const [acsResponse, setAcsResponse] = useState<AcsResponse | null>(null);

    const { t } = useTranslation<string, undefined>(stateinfo.i18nConfiguration.namespace);
    const navigate = useNavigate();

    useEffect(() => {
        async function init() {
            const authnRequest = await getAuthnRequest();

            if (authnRequest !== '' && authnRequest !== null && authnRequest !== undefined) {
                const samlLogin = async () => {
                    setIsLoading(true);
                    const i18nConfiguration = extractI18nConfigurationFromAuthRequest(authnRequest);
                    sendI18nConfiguration({ i18nConfiguration: i18nConfiguration });

                    //get the user login details
                    const userLogin = await getUserLogin();
                    if (!userLogin) {
                        setIsLoading(false);
                        return
                    }
                    const acsResponse = await setSaml(authnRequest, userLogin);
                    if (acsResponse) {
                        setAcsResponse(acsResponse);
                    } else {
                        setIsLoading(false);
                    }
                }
                samlLogin()
                    .then(
                        () => sendAuthnRequest({ authnRequest: authnRequest }),
                        err => {
                            console.error(err);
                            setIsLoading(false);
                        });
            }
            else {
                sendAuthnRequest({ authnRequest: authnRequest });
            }
        }
        init()
    }, [])

    function getContactValue(responseData, factoryType) {
        const contact = responseData.user.profile.person.contact
        if (contact.length == 0) {
            return ''
        }
        const contactType = { 'EMAIL': 'Email', 'SMS': 'Mobile' }[factoryType] || ''
        const found = contact.find(x => x.contactType === contactType)
        const result = found
            ? maskContact(contactType, found.contactValue)
            : ''
        return result
    }

    function maskContact(contactType, contactValue) {
        if (contactType !== 'Mobile') {
            return contactValue
        }
        if (contactValue.length < 6) {
            return contactValue
        }

        const numStars = contactValue.length - 6
        return contactValue.slice(0, 4) + '*'.repeat(numStars) + contactValue.slice(-2)
    }

    const handleClick = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        try {
            const responseData = await sendUserRequest({ email: email, password: password });
            if (responseData) {
                if (responseData.stateToken) {
                    let mfaFactorId = ''
                    let mfaFactorType = ''
                    const mfa = responseData.user.profile.mfa
                    if (mfa.length > 0) {
                        mfaFactorId = mfa[0].factorId;
                        mfaFactorType = mfa[0]['2ndFactorAuthentication'] || 'EMAIL'
                    }

                    setErr('');
                    // sending the tokens to the redux
                    sendFactorIdData({ factorId: mfaFactorId });
                    sendStateTokenData({ stateToken: responseData.stateToken });
                    sendContactValueData({ contactValue: getContactValue(responseData, mfaFactorType) });

                    if (stateinfo.authRequest === null) {
                        const i18nConfiguration = extractI18nConfigurationFromUserDetails(responseData.user);
                        sendI18nConfiguration({ i18nConfiguration: i18nConfiguration });
                    }

                    if (mfaFactorType) {
                        switch (mfaFactorType) {
                            case 'SMS':
                                navigate('/verify-sms');
                                break;
                            default:
                                navigate('/verify-email');
                                break;
                        }
                    }
                } else if (responseData.status === 'SUCCESS') {
                    const acsResponse = await setSaml(stateinfo.authRequest, responseData);
                    if (acsResponse) {
                        setAcsResponse(acsResponse);
                    } else {
                        setIsLoading(false);
                        setErr("Response of unkown origin");
                    }
                } else {
                    setErr("Response of unkown origin");
                }
            } else {
                setErr("Response of unkown origin");
            }
        } catch (err: any) {
            console.log(err)
            setErr(err.message);
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (name) => (value) => {
        setIsError(current => { return { ...current } });

        switch (name) {
            case "Login":
                setEmail(value);
                isempty.email = value.length === 0 ? "empty" : null;
                console.log(iserror.email)
                break;
            case "Password":
                setPassword(value);
                isempty.password = value.length === 0 ? "empty" : null;
                iserror.password =
                    value.length < 6 ? "At least 6 characters required" : "";
                break;
            default:
                break;
        }
        setIsError({
            ...iserror
        })
    };

    // go to forgot password section
    const forgotPassword = (e) => {
        navigate('/reset');
    }

    const loginButtonDisabled = Boolean(iserror.email) || Boolean(iserror.password) || Boolean(isempty.email) || Boolean(isempty.password) || isLoading || acsResponse !== null;
    const loginText: string = t('login.Login')
    const passwordText: string = t('login.Password')
    const signInText: string = t('description.SignIn')
    const forgotPasswordText: string = t('description.forgotPassword')

    return (
        <Container fluid className="d-flex flex-column container-no-padding">
            <LoginHeaderPicture back />
            <Row className="login-header-row">
                <Col>
                    <PageHeader>{t("login.header", {
                        applicationName: stateinfo.i18nConfiguration.applicationName
                    })}
                    </PageHeader>
                </Col>
            </Row>
            <Row className='justify-content-md-center flex-grow-1 login-row'>
                <Col>
                    <FormWrapper onSubmit={handleClick} className='form-group-1'>
                        <div className="forms--shape">
                            <FormDiv
                                classNameDiv="textfield user-input-wrp"
                                forhtml="Login"
                            >
                                <ZrTextInput
                                    id="field-input"
                                    config="shaped"
                                    name="Login"
                                    label={loginText}
                                    model={email}
                                    onChange={handleInputChange('Login')}
                                />
                            </FormDiv>
                        </div>

                        <div className="forms--shape">
                            <FormDiv
                                classNameDiv="textfield user-input-wrp"
                                forhtml="Password"
                            >
                                <ZrPasswordInput
                                    id="field-input-2"
                                    config="shaped"
                                    name="Password"
                                    label={passwordText}
                                    model={password}
                                    onChange={handleInputChange('Password')}
                                    invalid={Boolean(iserror.password)}
                                    help-text={iserror.password ? "This input is invalid" : ""}
                                />
                            </FormDiv>
                        </div>
                        <Message visible={err.length > 0}>
                            <p className='login-error'>{t('description.LoginErrorPart1')} <Link to='/reset'>{t('description.LoginErrorPart2')}</Link></p>
                        </Message>
                    </FormWrapper>
                    <WLoader loading={isLoading || acsResponse !== null} />
                </Col>
            </Row>
            <Row className="mt-auto login-row">
                <Col className="d-flex flex-column align-items-center">
                    <ZrButton onClick={handleClick} wide={true} disabled={loginButtonDisabled}>{signInText}</ZrButton>
                    <div className="forgot-password-div">
                        <ZrButton onClick={forgotPassword} config="link" className="mt-2">{forgotPasswordText}</ZrButton>
                    </div>
                </Col>
            </Row>
            {
                acsResponse !== null &&
                <SamlForm acsResponse={acsResponse} />
            }
        </Container>
    )
}

const mapStateToProps = state => ({
    stateinfo: state.main,
})

const mapDispatchToProps = {
    sendFactorIdData,
    sendStateTokenData,
    sendContactValueData,
    sendAuthnRequest,
    sendI18nConfiguration
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
