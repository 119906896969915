export async function verifyResetPasswordToken(token)
{
    return await fetch("/verifyResetToken", {
        method: 'POST',
        body: JSON.stringify({
            token: token
        }),
        headers: { "Content-Type": "application/json" }
    })
    .then((response => response.json()))
    .then( data => {
        if(data && data.user && data.user.ID)
        {
            return data.user.ID;
        }
        return false;
    })
    .catch((err) => {
        console.error(err);
        return false;
    })
}