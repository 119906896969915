import { Login, LoginAuth } from './Pages/Login'
import React, { Suspense, lazy } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Verification, VerificationAuth } from './Pages/Verification'

import Help from './Pages/Help/Help'
import Onboard from './Pages/Onboard/Onboard'
import Spinner from './Components/Spinner'

const Logout = lazy(() => import('./Pages/Logout/Logout'))
const SetPassword = lazy(() => import('./Pages/SetPassword/SetPassword'))
const ChooseTwoFactor = lazy(() => import('./Pages/TwoFactor/ChooseTwoFactor'))
const ResendActivationLink = lazy(() => import('./Pages/ResendActivationLink/ResendActivationLink'))
const ResetPassword = lazy(() => import('./Pages/ResetPassword/ResetPassword'))
const ChangePassword = lazy(() => import('./Pages/ChangePassword/ChangePassword'))
const PasswordChanged = lazy(() => import('./Pages/PasswordChanged/PasswordChanged'))

function AppRoutes() {
    return (
        <Suspense fallback={<Spinner />}>
            <Routes>
                {/* F1. Local login flow */}
                <Route path="/" element={<Login />} />
                <Route path="/help" element={<Help />} />

                {/* F2. SAML login flow */}
                {/* <Route path="/saml-login" element={<Login />} /> */}
                <Route path="/verify-email" element={<Verification verificationType="email" />} />
                <Route path="/verify-sms" element={<Verification verificationType="sms" />} />

                {/* F3. SAML logout flow */}
                <Route path="/saml/slo" element={<Logout />} />

                {/* F4. OAuth 2.0 login flow */}
                <Route path="/auth-login" element={<LoginAuth />} />
                <Route path="/auth-login/verify-email" element={<VerificationAuth verificationType="email" />} />
                <Route path="/auth-login/verify-sms" element={<VerificationAuth verificationType="sms" />} />

                {/* F5. Reset password flow */}
                <Route path="/reset" element={<ResetPassword />} />

                {/* F6. Reset password email flow */}
                <Route path="/reset-password" element={<ChangePassword />} />
                <Route path="/password-changed" element={<PasswordChanged />} />

                {/* F7. Activation email flow */}
                <Route path="/activation" element={<Onboard />} />
                <Route path="/resend-activation-link" element={<ResendActivationLink />} />
                <Route path="/set-password" element={<SetPassword />} />
                <Route path="/two-factor" element={<ChooseTwoFactor />} />
            </Routes>
        </Suspense>
    )
}

export default AppRoutes
