import React, { PropsWithChildren } from 'react'
import './Message.css'

type MessageProps = {
    visible: boolean;
}

function Message(props: PropsWithChildren<MessageProps>) {
    if (!props.visible) {
        return null
    }

    return (
        <div className="message">
            {props.children}
        </div>
    )
}

export default Message
