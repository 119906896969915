import PropTypes from 'prop-types';
import React from 'react';
import './InputField.css';

const InputField = (props)  => {
    return (
        <input id={props.id} 
            name={props.name} 
            className={props.className} 
            value={props.value} 
            type={props.type} 
            onChange={props.onChange} 
            placeholder={props.placeholder}
            disabled={props.disabled}
            />
    )
}

InputField.propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    value: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
};

export default InputField;