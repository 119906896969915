import axios from 'axios';


axios.defaults.withCredentials = true; //Setting the withCredentials to true allows the cookie for cross domain requests

export async function getUserLogin(){
    return axios.get('/session', 
    {
        headers: { "Content-Type": "application/json" }
    })
    .then(response => {
        if(response.statusText === 'OK')
        {
            // If request is not successful, display error message
        return response.data;
        }
    }).catch((err) => {
        console.error(err);
        return false;
    })
}