const policyRules = new Map();

policyRules.set('minLength', {
    validator: (password, minLength) => password.length >= minLength,
    translation: "passwordPolicy.LengthPolicyDescription"
});
policyRules.set('minLowerCase', {
    validator: (password, minLength) => {
        const matches = password.match(/[a-z]/g);
        return matches != null && matches.length >= minLength;
    },
    translation: "passwordPolicy.LowercasePolicyDescription"
});
policyRules.set('minUpperCase', {
    validator: (password, minLength) => {
        const matches = password.match(/[A-Z]/g);
        return matches != null && matches.length >= minLength;
    },
    translation: "passwordPolicy.UppercasePolicyDescription"
});
policyRules.set('minNumber', {
    validator: (password, minLength) => {
        const matches = password.match(/[0-9]/g);
        return matches != null && matches.length >= minLength;
    },
    translation: "passwordPolicy.NumberPolicyDescription"
});
policyRules.set('minSymbol', {
    validator: (password, minLength) => {
        const matches = password.match(/[^A-Za-z0-9]/g);
        return matches != null && matches.length >= minLength;
    },
    translation: "passwordPolicy.SymbolPolicyDescription"
});

export default policyRules;