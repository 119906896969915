import './AppDesktop.css';

import AppRoutes from './AppRoutes'
import LoginDesktopBottomImage from './Assets/login-desktop-bottom.png';
import Navigation from './Components/Navigation'
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Footer from './Components/Footer';

function AppDesktop() {
  return (
    <Router>
      <div className="app-desktop-grid-container">
        <div className="app-desktop-grid-item-header">
            <Navigation showLabel={true} />
        </div>
        <div className="app-desktop-grid-item-left" />
          <main className="app-desktop-grid-item-main">
            <div className="app-desktop-content main-content">
              <AppRoutes />
              <Footer marginTop />
            </div>
          </main>
        <div />
      </div>
    </Router>
  )
}

export default AppDesktop
