import PropTypes from 'prop-types';
import React from 'react';
import './FormDiv.css'

const FormDiv = (props) => {
    return (
        <div className={props.classNameDiv}>
            {props.children}
            <label className={props.classNameLabel} htmlFor={props.forhtml}>{props.label}</label>
        </div>
    )
}

FormDiv.propTypes = {
    classNameDiv: PropTypes.string,
    classNameLabel: PropTypes.string,
    forhtml: PropTypes.string,
    label: PropTypes.string,
    children: PropTypes.node
}

export default FormDiv;