import PropTypes from 'prop-types';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useTranslation } from 'react-i18next';
import './Navigation.css'
import { connect } from 'react-redux';

function Navigation(props) {
    const { i18nNamespace } = props;
    const { t } = useTranslation(i18nNamespace);
    return (
        <Navbar>
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto  ">
                    </Nav>
                    <Nav>
                        <Nav.Link href="/help" className="">
                            <span className="icon icon--communication-help_outline icon-wrap navLink"></span>
                            {props.showLabel && <> {t('navigation.Help')}</>}
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

Navigation.propTypes = {
    showLabel: PropTypes.bool,
    i18nNamespace: PropTypes.string
}

const mapStateToProps = state => ({
    i18nNamespace: state.main.i18nConfiguration.namespace
})

export default connect(mapStateToProps)(Navigation);