import { getUserDetails, getUserDetailsByLogin } from './setUserOnboard'
import { getConfiguration } from './configuration'

interface Configuration {
    type: string
    app?: string
    role?: string
    group?: string
}

export async function buildUserConfiguration(userId: string, serviceProviderId: string): Promise<any> {
    const user = await getUserDetails(userId)
    if (!user) {
        throw new Error("No valid user found")
    }

    return await findConfiguration(user, serviceProviderId)
}

export async function buildUserConfigurationByLogin(userLogin: string, serviceProviderId: string): Promise<any> {
    const user = await getUserDetailsByLogin(userLogin)
    if (!user) {
        throw new Error("No valid user found")
    }

    return await findConfiguration(user, serviceProviderId)
}

async function findConfiguration(user: any, serviceProviderId: string): Promise<any> {
    const userConfigurations = await getConfiguration(serviceProviderId)
    const userConfiguration = userConfigurations.find((uc: Configuration) => {
        switch (uc.type.toLowerCase()) {
            case 'approle':
                return user.userDetails.profile.appRoles.includes(`${uc.app}.${uc.role}`)
            case 'group':
                return user.userDetails.profile.group.includes(uc.group)
            case 'any':
                return true
        }
        return false
    })
    if (userConfiguration === undefined) {
        throw new Error("No valid configuration found");
    }

    return userConfiguration
}
